import { useRef } from "react";

import { sliderWorkers } from "../../data/sliderWorkers";

import useObserver from "../../hooks/useObserver";

import DescriptionText from "../Utils/DescriptionText/DescriptionText";
import SliderWorkers from "../Utils/SliderWorkers/SliderWorkers";

import { aboutCards } from "../../data/aboutCards";

import AboutCard from "./AboutCard/AboutCard";
import cl from "./MainPage.module.scss";
import { ProjectCard } from "./ProjectCard/ProjectCard";
import WorkerCard from "./WorkerCard/WorkerCard";
const MainPage = () => {
  const sectionHomeRef = useRef<HTMLElement>(null!);
  const sectionServicesRef = useRef<HTMLElement>(null!);
  const sectionCeoRef = useRef<HTMLElement>(null!);
  const sectionWorkersRef = useRef<HTMLElement>(null!);
  const sectionProjectsRef = useRef<HTMLDivElement>(null!);
  useObserver([sectionHomeRef, sectionServicesRef, sectionCeoRef, sectionWorkersRef, sectionProjectsRef]);

  const email = "info@otr-it.ru";
  const emailHeader = "Запрос на разработку IT решений";

  return (
    <>
      <section ref={sectionHomeRef} id="home" className={cl.home}>
        <div className={cl.homeContent}>
          <div className={cl.info}>
            <h2 className={cl.headerText}>
              Оптимизация транспортных
              <br />
              решений ИТ
            </h2>
            <DescriptionText fontWeight="700" fontSize="1.4rem" lineHeight="34px" margin="0 0 20px 0">
              Разработчик программного обеспечения для транспортных и логистических компаний в мультиплатформенных средах
            </DescriptionText>
          </div>

          <a target="_blank" href={`mailto:${email}?subject=${emailHeader}`} rel="noreferrer">
            <picture>
              <source srcSet={require("../../imgs/letterQR_TransparentEdgesShell/letterQR_TransparentEdgesShell.avif")} type="image/avif" />
              <source srcSet={require("../../imgs/letterQR_TransparentEdgesShell/letterQR_TransparentEdgesShell.webp")} type="image/webp" />
              <img className={cl.qrCodeLetter} src={require("../../imgs/letterQR_TransparentEdgesShell/letterQR_TransparentEdgesShell.png")} alt="qr code" />
            </picture>
          </a>
        </div>
      </section>
      <section ref={sectionServicesRef} id="services" className={cl.servicesWrapper}>
        <div className={cl.cardsWrapper}>
          {aboutCards.map(({ description, title, imgName }, index) => (
            <AboutCard key={index} description={description} title={title} imgName={imgName} />
          ))}
        </div>
      </section>
      <section ref={sectionCeoRef} id="ceo">
        <div className={cl.ceoWrapper}>
          <picture>
            <source srcSet={require("../../imgs/workers/ceo/ceo.avif")} type="image/avif" />
            <source srcSet={require("../../imgs/workers/ceo/ceo.webp")} type="image/webp" />
            <img src={require("../../imgs/workers/ceo/ceo.png")} className={cl.ceoImg} alt="ceo" />
          </picture>
          <div className={cl.ceoContent}>
            <div className={cl.ceoHeader}>
              Храбров Андрей Александрович
              <br />
              CEO
            </div>
            <DescriptionText fontWeight="700" fontSize="1.4rem" lineHeight="34px" margin="0 0 20px 0">
              Наша компания больше 18 лет занимается разработкой и внедрением технически сложного ПО для государственных организаций, ведомств и предприятий до 5000 АРМ.
              <br />В нашем арсенале есть:
              <br />
              <ul className={cl.listOfCeoBenefits}>
                <li className={cl.ceoBenefitWrapper}>
                  <span className={cl.ceoBenefit}>
                    Опыт <div className={cl.listDot} />
                  </span>
                </li>
                <li className={cl.ceoBenefitWrapper}>
                  <span className={cl.ceoBenefit}>
                    Знания <div className={cl.listDot} />
                  </span>
                </li>
                <li className={cl.ceoBenefitWrapper}>
                  <span className={cl.ceoBenefit}>
                    Материально-техническая база <div className={cl.listDot} />
                  </span>
                </li>
                <li className={cl.ceoBenefitWrapper}>
                  <span className={cl.ceoBenefit}>
                    Сотрудничество <div className={cl.listDot} />
                  </span>
                  с ФГАОУ ВО «ЮУрГУ (НИУ)»
                </li>
                <li className={cl.ceoBenefitWrapper}>
                  <span className={cl.ceoBenefit}>
                    Партнерские <div className={cl.listDot} />
                  </span>
                  отношения с ведущими поставщиками оборудования (отечественные и зарубежные)
                </li>
              </ul>
            </DescriptionText>
          </div>
        </div>
      </section>
      <section ref={sectionWorkersRef} className={cl.workersWrapper} id="workers">
        <SliderWorkers>
          {sliderWorkers.map((sliderWorker, index) => (
            <WorkerCard key={index} {...sliderWorker} />
          ))}
        </SliderWorkers>
      </section>
      <section className={cl.projectsWrapper} id="projects">
        <div className={cl.projectsHeader}>Разработки</div>
        <div id="projects" ref={sectionProjectsRef!} className={cl.projectRef}></div>
        <div className={cl.projectCardsWrapper}>
          r
          <ProjectCard imgName="computerVision" title="Компьютерное зрение">
            <div className={cl.projectDescriptionIndent}>
              Уникальная разработка, программно-аппаратного комплекса, позволяющая с точностью до тысячной определять объем и массу груза, со сложными габаритами (бампер, конус, шар и пр.).
            </div>
            <div className={cl.projectDescriptionIndent}>
              В настоящее время ведутся работы по скрещиванию истории определения габаритов и характера груза и передачи данных в систему искусственного интеллекта (ИИ), чтобы в будущем можно было
              максимально точно определять массу по габаритам и характеру (для определения точной стоимости при заборе груза без взвешивания)
            </div>
            <div>В разработке используются лазерные 3D-камеры Intel RealSense, комплекс ПО (C#, Python, JavaScript)</div>
          </ProjectCard>
          <ProjectCard imgName="ecosystems" title="Экосистемы для Транспортных Компаний">
            <div className={cl.projectDescriptionIndent}>Разработка и внедрение единой системы учета персонала, учета грузоперевозок, управленческого и бухгалтерского учета.</div>
            <div className={cl.projectDescriptionIndent}>
              Система активно используется всеми сотрудниками компании, от руководителей, до грузчиков и позволяет оценивать и эффективно использовать каждую кадровую единицу компании.
            </div>
            Заказчик - Группа Компаний "ТК "ЛУЧ" (включает в себя больше 80 складов, более 1000 населённых пунктов, доступных для доставок, больше 1000 сотрудников)
          </ProjectCard>
          <ProjectCard imgName="infrastructure" title="Создание и обслуживание корпоративной IT-инфраструктуры">
            <div className={cl.projectDescriptionIndent}>
              Мы создаём и обслуживаем корпоративные сети, с комбинированными и облачными моделями инфраструктуры. При моделировании системы мы учитываем множество факторов (характер работы
              сотрудников, уровень безопасности для хранения данных, географическое расположение офисов и производственных мощностей, 152-ФЗ и т.д.)
            </div>
            Наиболее важным моментом, который мы учитываем при создании корпоративных сетей, является вектор развития компании. Оптимизация штата, интенсивное развитие филиальной сети, стабильное
            функционирование в рамках текущей геолокации - каждая компания развивается своим путём, и мы помогаем сфокусироваться и определить, как будем развивать инфраструктуру
          </ProjectCard>
        </div>
      </section>
    </>
  );
};

export default MainPage;
